.dbitem {
    border: 1px solid #eee;
    border-radius: 20px;
    padding: 16px;
    margin-top: 16px;
    background-color: #fcfcfc;
    position: relative;
}

.dbitem .trash-btn {
    position: absolute;
    right: 2px;
    top: 2px;
    width: auto;
}

.dirItem {
    border: 1px solid #eee;
    border-radius: 20px;
    padding: 16px;
    margin-top: 16px;
    background-color: #fcfcfc;
    position: relative;
}

.dirItem .trash-btn {
    position: absolute;
    right: 2px;
    top: 2px;
    width: auto;
}

.modal-body .d-flex {
    align-items: center;
}



.exdir {

    margin-top: 16px;
    background-color: #fcfcfc;
    position: relative;
}

.exdir .trash-btn {
    position: absolute;
    right: 0;
    top: 0;
}

.exdir input {
    padding-right: 40px;
}



@media (min-width: 992px) {
    .card.card-backupInstruction {
        display: flex;
        flex-direction: row;
    }

    .card.card-backupInstruction .card-body {
        width: 60%;
    }

    .card.card-backupInstruction>.list-group {
        border-left: 1px solid var(--border);
        min-width: 40%;
        max-width: 40%;
    }

    
}



label span {
    color: #ccc;
}